<template>
    <header class="relative">
        <section class="absolute w-full z-20 ">
            <div class="w-full container mx-auto flex justify-between items-center p-4 lg:p-10">
                <div class="text-white text-xl lg:text-6xl uppercase font-mono">Kick-it</div>
            <nav class="">
          <ul class="flex gap-4 ">
              <li class="text-white uppercase"><Link href="/">Home</Link></li>
              <li class="text-white uppercase"><Link href="/games">Games</Link></li>
              <li class="text-white uppercase"><Link href="/users">Players</Link></li>
              <li class="text-white uppercase hidden"><Link href="/rules">Rules</Link></li>
          </ul></nav>
            </div>
            
      
        </section>
        
      <div class="before:bg-black/40 before:w-full before:h-full before:absolute before:z-0 ">
        <img class="max-h-[40vh] w-full object-cover" :src="randomImage()">
        </div>
    </header>

    <main class="container mx-auto p-10">
        <slot></slot>
    </main>
    
    <footer>

    </footer>
</template>

<script setup>
 import { Link } from '@inertiajs/vue3'


   let randomImage = () => {
       let images = [
           'pexels-markus-winkler-13548815.jpg',
           'gallery-image-3-1195x800-original.jpg',
           'bg_3.jpg',
           'pexels-fabricio-trujillo-54567.jpg',
           'pexels-mike-1171084.jpg'
           
       ];
       return '/storage/' + images[Math.floor(Math.random() * images.length)];
   }


</script>