<template>
    <Layout>
        <Link v-if="game" class="block" :href="'/games/' + game.id">
        <section class="hidden pb-10 -mt-48 md:w-2/3 lg:w-1/2 mx-auto">
            <GameTable :game="game"></GameTable>
        </section>
        </Link>

        <section class="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div class="col-span-2 pb-6">
                <div class="border-b border-gray-200 pb-3">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Neues Spiel erstellen
                    </span>
                </div>

                <form class="" @submit.prevent="submit">
                    <div
                        class="border border-gray-200 mt-6 px-4 py-10 bg-white flex flex-col justify-center items-center mb-10">
                        <div class="grid grid-cols-5 max-w-3xl gap-4">
                            <div class="grid grid-cols-2 col-span-2 gap-4 border border-gray-200 p-4 items-center">
                                <label for="team_one_player_defense"
                                    class="block uppercase text-xs text-gray-400">Defense</label>
                                <div class="bg-gray-200 rounded p-2">
                                    <select id="team_one_player_defense"
                                        class="bg-transparent focus:outline-none cursor-pointer"
                                        name="team_one_player_defense" v-model="form.team_one_player_defense">
                                        <option v-for="user in users" :value="user.id">
                                            {{ user.name }}
                                        </option>
                                    </select>
                                </div>

                                <label for="team_one_player_defense"
                                    class="block uppercase text-xs text-gray-400">Offense</label>
                                <div class="bg-gray-200 rounded p-2">
                                    <select id="team_one_player_defense"
                                        class="bg-transparent focus:outline-none cursor-pointer"
                                        name="team_one_player_offense" v-model="form.team_one_player_offense">
                                        <option v-for="user in users" :value="user.id">
                                            {{ user.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="place-content-center">
                                <div class="flex justify-around"></div>
                                <div class="flex justify-center items-center relative mt-5">
                                    <span class="block absolute w-full border-gray-200 border-b"></span>
                                    <span
                                        class="block absolute rounded-full w-8 h-8 border bg-white border-gray-200 text-xs font-bold uppercase p-1.5 text-green-600 text-center">VS</span>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 col-span-2 gap-4 border border-gray-200 p-4 items-center">
                                <label for="team_one_player_defense"
                                    class="block uppercase text-xs text-gray-400">Defense</label>
                                <div class="bg-gray-200 rounded p-2">
                                    <select id="team_one_player_defense"
                                        class="bg-transparent focus:outline-none cursor-pointer"
                                        name="team_two_player_defense" v-model="form.team_two_player_defense">
                                        <option v-for="user in users" :value="user.id">
                                            {{ user.name }}
                                        </option>
                                    </select>
                                </div>

                                <label for="team_one_player_defense"
                                    class="block uppercase text-xs text-gray-400">Offense</label>
                                <div class="bg-gray-200 rounded p-2">
                                    <select id="team_one_player_defense"
                                        class="bg-transparent focus:outline-none cursor-pointer"
                                        name="team_two_player_offense" v-model="form.team_two_player_offense">
                                        <option v-for="user in users" :value="user.id">
                                            {{ user.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-span-5 flex justify-center pt-2">
                                <button class="bg-green-600 uppercase font-bold text-sm p-4 rounded-sm text-white"
                                    type="submit" :disabled="form.processing">
                                    Los geht's!
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="border-b border-gray-200 pb-3">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Letzte Spiele
                    </span>
                </div>

                <GameResult :game="games[0]" layout="big"></GameResult>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <GameResult :game="games[1]" layout="small"></GameResult>
                    <GameResult :game="games[2]" layout="small"></GameResult>
                    <GameResult :game="games[3]" layout="small"></GameResult>
                </div>

                <div class="border-b border-gray-200 mt-6 py-3">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Schulden
                    </span>
                </div>

                <div class="border border-gray-200 mt-6 bg-white flex flex-col justify-center items-center mb-10">
                    <table class="table-fixed w-full">
                        <thead class="bg-green-600 text-xs uppercase text-white text-left">
                            <tr>
                                <th class="px-4 py-3 w-40">Spieler</th>
                                <th class="text-center">Eigentore</th>
                                <th class="text-center">Mitteltore</th>
                                <th class="text-center">Zu Null</th>
                                <th class="text-center">Torwarttor</th>
                                <th class="text-center">Gesamt</th>
                                <th class="text-center">Offen</th>
                            </tr>
                        </thead>
                        <tbody class="text-sm uppercase text-gray-900 text-left">
                            <tr v-for="(debts, index) in sortDebtsByAttribute()">
                                <td class="px-4 py-3 flex items-center gap-2">
                                    <img class="aspect-square object-cover w-10" :src="debts.profile_image" />{{ debts.name }}
                                </td>
                                <td class="text-center">
                                    <span v-if="debts.debtsOwnGoals">{{ debts.debtsOwnGoals }} <span class="text-gray-400 text-xs" >({{ debts.debtsOwnGoalsDebt }} €)</span></span>
                                    <span v-else>-</span>
                                </td>
                                
                                <td class="text-center">
                                    <span v-if="debts.debtsMiddleGoals">{{ debts.debtsMiddleGoals }} <span class="text-gray-400 text-xs" >({{ debts.debtsMiddleGoalsDebt }} €)</span></span>
                                    <span v-else>-</span>
                                </td>

                                <td class="text-center">
                                    <span v-if="debts.debtsTenNull">{{ debts.debtsTenNull }} <span class="text-gray-400 text-xs" >({{ debts.debtsTenNullDebt }} €)</span></span>
                                    <span v-else>-</span>
                                </td>

                                <td class="text-center">
                                    <span v-if="debts.debtsGoalkeeperWin">{{ debts.debtsGoalkeeperWin }} <span class="text-gray-400 text-xs">({{ debts.debtsGoalkeeperWinDebt }} €)</span></span>
                                    <span v-else>-</span>
                                </td>
                                
                                <td class="text-center">
                                    <span v-if="debts.debtsTotal">{{ debts.debtsTotal }} €</span>
                                    <span v-else>-</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="debts.debtsNotPaid" :class="{ 'text-red-500' : debts.debtsNotPaid }">{{ debts.debtsNotPaid }} €</span>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            <div class="">
                <div class="border-b border-gray-200 pb-3">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Tabelle
                    </span>
                </div>

                <div class="border border-gray-200 mt-6 mb-3 bg-white">
                    <table class="table-fixed w-full">
                        <thead class="bg-green-600 text-xs uppercase text-white text-left">
                            <tr>
                                <th class="px-4 py-3 w-40">Spieler</th>
                                <th class="text-center">Win %</th>
                                <th class="text-center">Spiele</th>
                                <th class="text-center">Siege</th>
                                <th class="text-center">Elo</th>
                            </tr>
                        </thead>
                        <tbody class="text-sm uppercase text-gray-900 text-left">
                            <Link :href="'/users/' + user.id" class="table-row align-middle" v-for="(user, index) in sortUsersByAttribute('rating')">
                                <td class="px-4 py-3 flex items-center gap-2">
                                    <img class="aspect-square object-cover w-10" :src="user.profile_image" />{{ user.name }}
                                </td>
                                <td>
                                    <div v-if="user.games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: ' + (user.wins / user.games) * 100 + '%' "></div>
                                        </div>
                                    </div>
                                    <div v-if="!user.games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: 0%'"></div>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td class="text-center">{{ user.games }}</td>
                                <td class="text-center">{{ user.wins }}</td>
                                <td class="text-center"> {{ Math.round(user.rating) }} </td>
                            </Link>
                        </tbody>
                    </table>
                </div>

                <div class="border-b border-gray-200 pb-3 pt-6">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Beste Offensiv Spieler
                    </span>
                </div>

                <div class="border border-gray-200 mt-6 mb-3 bg-white">
                    <table class="table-fixed w-full">
                        <thead class="bg-green-600 text-xs uppercase text-white text-left">
                            <tr>
                                <th class="px-4 py-3 w-40">Spieler</th>
                                <th class="text-center">Win %</th>
                                <th class="text-center">Spiele</th>
                                <th class="text-center">Siege</th>
                                <th class="text-center">Elo</th>
                            </tr>
                        </thead>
                        <tbody class="text-sm uppercase text-gray-900 text-left">
                            <Link :href="'/users/' + user.id" class="table-row align-middle" v-for="(user, index) in sortUsersByAttribute('offense_rating')">
                                <td class="px-4 py-3 flex items-center gap-2">
                                    <img class="aspect-square object-cover w-10" :src="user.profile_image" />{{ user.name }}
                                </td>
                                <td>
                                    <div v-if="user.offense_games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: ' + (user.offense_wins / user.offense_games) * 100 + '%' "></div>
                                        </div>
                                    </div>
                                    <div v-if="!user.offense_games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: 0%'"></div>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td class="text-center">{{ user.offense_games }}</td>
                                <td class="text-center">{{ user.offense_wins }}</td>
                                <td class="text-center"> {{ Math.round(user.offense_rating) }} </td>
                            </Link>
                        </tbody>
                    </table>
                </div>

                <div class="border-b border-gray-200 pb-3 pt-6">
                    <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                        Beste Defensiv Spieler
                    </span>
                </div>

                <div class="border border-gray-200 mt-6 mb-3 bg-white">
                    <table class="table-fixed w-full">
                        <thead class="bg-green-600 text-xs uppercase text-white text-left">
                            <tr>
                                <th class="px-4 py-3 w-40">Spieler</th>
                                <th class="text-center">Win %</th>
                                <th class="text-center">Spiele</th>
                                <th class="text-center">Siege</th>
                                <th class="text-center">Elo</th>
                            </tr>
                        </thead>
                        <tbody class="text-sm uppercase text-gray-900 text-left">
                            <Link :href="'/users/' + user.id" class="table-row align-middle" v-for="(user, index) in sortUsersByAttribute('defense_rating')">
                                <td class="px-4 py-3 flex items-center gap-2">
                                    <img class="aspect-square object-cover w-10" :src="user.profile_image" />{{ user.name }}
                                </td>
                                <td>
                                    <div v-if="user.defense_games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: ' + (user.defense_wins / user.defense_games) * 100 + '%' "></div>
                                        </div>
                                    </div>
                                    <div v-if="!user.defense_games" class="flex w-full items-center gap-3">
                                        <div class="w-full bg-gray-200 h-1.5">
                                            <div class="bg-green-600 h-1.5" :style="'width: 0%'"></div>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td class="text-center">{{ user.defense_games }}</td>
                                <td class="text-center">{{ user.defense_wins }}</td>
                                <td class="text-center"> {{ Math.round(user.defense_rating) }} </td>
                            </Link>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>

        <p class="mt-2"></p>
    </Layout>
</template>

<script setup>
import Layout from "../Shared/Layout.vue";
import GameTable from "../Shared/GameTable.vue";
import GameResult from "../Shared/GameResult.vue";
import { useForm } from "@inertiajs/vue3";
import { Link } from "@inertiajs/vue3";
import { ref, computed } from 'vue';

const props = defineProps({
    headline: String,
    bodytext: String,
    games: Array,
    users: Array,
    debts: Array,
});

let form = useForm({
    team_one_player_offense: "",
    team_one_player_defense: "",
    team_two_player_offense: "",
    team_two_player_defense: "",
});

let submit = () => {
    form.post("/games");
};

// const sortUsersByAttribute = computed((attribute = 'rating') => {
//   let result = Object.values(props.users);
//   result.sort((a, b) => b[attribute] - a[attribute]);
//   return result;
// });

function sortDebtsByAttribute(attribute = 'debtsTotal') {
  let result = Object.values(props.debts);
  console.log(result);
  result.sort((a, b) => b[attribute] - a[attribute]);
  return result;
}

function sortUsersByAttribute(attribute = 'rating') {
  let result = Object.values(props.users);
  result.sort((a, b) => b[attribute] - a[attribute]);
  return result;
}
</script>
