<template>
  <template v-if="layout == 'big' || layout == 'stats'">
    <Link :href="'/games/' + game.id"
      class="border border-gray-200 mt-6 px-4 py-10 bg-white flex flex-col justify-center items-center">
    <p class="uppercase font-semibold text-gray-400 text-sm mt-5">
      {{ formatDate(game.created_at) }}
    </p>
    <p class="font-bold text-2xl uppercase pb-8 text-gray-400 hidden">
      {{ formatDate(game.created_at) }}
    </p>
    <div class="grid grid-cols-5 max-w-3xl gap-4 -mt-10">
      <div class="grid grid-cols-2 col-span-2 gap-4 border border-gray-200 p-4">
        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 1" class="w-full">
            <img class="aspect-square object-cover w-full" :src="'/' + user.profile_image" />
            <p class="pt-4 uppercase font-semibold text-sm">{{ user.name }}</p>
            <UserWinPercentage :user="user" />
          </div>
        </template>
      </div>
      <div class="place-content-center">
        <div class="flex justify-around">
          <div class="text-5xl text-gray-400" :class="{ '!text-green-600': game.score_team_one >= '10' }">{{
    game.score_team_one }}</div>
          <div class="text-5xl text-gray-400" :class="{ '!text-green-600': game.score_team_two >= '10' }">{{
    game.score_team_two }}</div>
        </div>
        <div class="flex justify-center items-center relative mt-5">
          <span class="block absolute w-full border-gray-200 border-b"></span>
          <span
            class="block absolute rounded-full w-8 h-8 border bg-white border-gray-200 text-xs font-bold uppercase p-1.5 text-green-600 text-center">VS</span>
        </div>
      </div>
      <div class="grid grid-cols-2 col-span-2 gap-4 border border-gray-200 p-4">
        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 2" class="w-full">
            <img class="aspect-square object-cover w-full" :src="'/' + user.profile_image" />
            <p class="pt-4 uppercase font-semibold text-sm">{{ user.name }}</p>
            <UserWinPercentage :user="user" />
          </div>
        </template>
      </div>

      <div v-if="layout == 'stats'" class="col-span-5 gap-4 border border-gray-200 p-4 justify-center grid grid-cols-5">
        <div
          class="border border-gray-200 place-content-center text-center col-start-3 -mt-11 p-4 uppercase font-bold text-sm bg-white">
          Statistik
        </div>
        <div class="col-span-5 flex">
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            10
          </div>
          <div
            class="bg-gray-200 flex-grow text-xs place-content-center text-center font-semibold py-2 uppercase text-gray-500">
            Eigentore
          </div>
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            4
          </div>
        </div>
        <div class="col-span-5 flex">
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            10
          </div>
          <div
            class="bg-gray-200 flex-grow text-xs place-content-center text-center font-semibold py-2 uppercase text-gray-500">
            Mitteltore
          </div>
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            4
          </div>
        </div>
        <div class="col-span-5 flex">
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            10
          </div>
          <div
            class="bg-gray-200 flex-grow text-xs place-content-center text-center font-semibold py-2 uppercase text-gray-500">
            Alarm
          </div>
          <div class="bg-gray-900 text-gray-100 w-10 text-sm place-content-center text-center font-semibold">
            4
          </div>
        </div>
      </div>
    </div>
    </Link>
  </template>
  <template v-if="layout == 'small'">
    <Link :href="'/games/' + game.id"
      class="border border-gray-200 mt-6 bg-white flex flex-col justify-center items-center">
    <div class="max-w-3xl gap-4">
      <div class="grid grid-cols-3 col-span-2 gap-4 p-4 items-center ">
        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 1" class="w-full">
            <img class="aspect-square object-cover w-full" :src="user.profile_image" />
          </div>
        </template>
        <div class="text-4xl text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_one >= '10' }">{{
    game.score_team_one }}</div>
      </div>
      <div class="place-content-center">
        <div class="flex justify-around">


        </div>
        <div class="flex justify-end items-center relative">
          <span class="block absolute w-full border-gray-200 border-b"></span>
          <span
            class="block absolute rounded-full w-8 h-8 border bg-white border-gray-200 text-xs font-bold uppercase p-1.5 text-green-600 text-center mr-4">VS</span>
        </div>
      </div>
      <div class="grid grid-cols-3 col-span-2 gap-4 p-4 items-center ">
        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 2" class="w-full">
            <img class="aspect-square object-cover w-full" :src="user.profile_image" />

          </div>
        </template>
        <div class="text-4xl text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_two >= '10' }">{{
    game.score_team_two }}</div>
      </div>
      <div class="border-t text-center">

        <p class="font-bold text-xs uppercase p-4 text-gray-400 ">
          {{ formatDate(game.created_at) }}
        </p>
      </div>
    </div>
    </Link>
  </template>
  <template v-if="layout == 'row'">
    <Link :href="'/games/' + game.id" class="border border-gray-200 mt-6 bg-white flex justify-center items-center">
    <div class="gap-4 grid grid-cols-9">
      <div class="items-center p-4 col-span-2 flex">{{ formatDate(game.created_at) }}</div>
      <div class="grid grid-cols-3 col-span-2 gap-4 p-4 items-center ">
        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 1" class="w-full">
            <img class="aspect-square object-cover w-full" :src="user.profile_image" />
          </div>
        </template>
        <div class="text-4xl text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_one >= '10' }">{{
    game.score_team_one }}</div>
      </div>
      <div class="place-content-center">
        <div class="flex items-center justify-center relative h-full"><span
            class="block absolute w-1/2 border-gray-200 border-r -ml-[50%] h-full"></span><span
            class="block absolute rounded-full w-8 h-8 border bg-white border-gray-200 text-xs font-bold uppercase p-1.5 text-green-600 text-center"
            style="
">VS</span></div>
      </div>
      <div class="grid grid-cols-3 col-span-2 gap-4 p-4 items-center ">
        <div class="text-4xl text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_two >= '10' }">{{
    game.score_team_two }}</div>

        <template v-for="user in game.users">
          <div v-if="user.pivot.team == 2" class="w-full">
            <img class="aspect-square object-cover w-full" :src="user.profile_image" />

          </div>
        </template>
      </div>
      <div class="items-center justify-end p-4 col-span-2 flex"><button class="button">
                    Details
                </button></div>
      <div class="border-t text-center" v-if="false">

        <p class="font-bold text-xs uppercase p-4 text-gray-400 ">
          {{ formatDate(game.created_at) }}
        </p>
      </div>
    </div>
    </Link>


  </template>
  <template v-if="layout == 'row-alt2'">

    <tr>
      <td class="px-4 py-3">{{ formatDate(game.created_at) }}</td>

      <template v-for="user in game.users">
        <td v-if="user.pivot.team == 1">{{ user.name }}</td>
      </template>
      <td>VS</td>
      <template v-for="user in game.users">
        <td v-if="user.pivot.team == 2">{{ user.name }}</td>
      </template>
      <td>
        <span class=" text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_one >= '10' }">{{
    game.score_team_one }}</span>
        <span class=" text-gray-400">:</span>
        <span class=" text-gray-400 text-center" :class="{ '!text-green-600': game.score_team_two >= '10' }">{{
    game.score_team_two }}</span>
      </td>
    </tr>

  </template>
  <template v-if="layout == 'row-alt'">
    <Link :href="'/games/' + game.id" class="border border-gray-200  bg-white block">
    <div class="">
      <div class="bg-white flex items-center px-4">
        <div class="flex gap-4">
          <template v-for="user in game.users">
            <div v-if="user.pivot.team == 1" class="w-full py-4">
              <img class="aspect-square object-cover w-12" :src="user.profile_image" />
            </div>
          </template>
        </div>

        <div
          class="relative w-32 h-20 mx-10 before:absolute before:top-0 before:right-1 before:left-1 before:bottom-0 before:bg-gray-200 before:skew-x-[20deg] after:absolute after:top-0 after:right-1 after:left-1 after:bottom-0 after:bg-gray-200 after:-skew-x-[20deg]">
          <div class="absolute z-10 w-32 h-20 flex justify-center items-center gap-2">
            <div class="text-4xl text-gray-400 text-center"
              :class="{ '!text-green-600': game.score_team_one >= '10' }">{{
    game.score_team_one }}</div>
            <div class="text-2xl text-gray-400">:</div>
            <div class="text-4xl text-gray-400 text-center"
              :class="{ '!text-green-600': game.score_team_two >= '10' }">{{
    game.score_team_two }}</div>
          </div>

        </div>
        <div class="flex gap-4">
          <template v-for="user in game.users">
            <div v-if="user.pivot.team == 2" class="w-full py-4">
              <img class="aspect-square object-cover w-12" :src="user.profile_image" />
            </div>
          </template>
        </div>
      </div>
    </div>
    </Link>
  </template>
</template>

<script setup>

import moment from 'moment';
import UserWinPercentage from "../Shared/UserWinPercentage.vue";
import { Link } from '@inertiajs/vue3'

const props = defineProps({
  game: Object,
  layout: String
});

let formatDate = (dateString) => {
  // Parse the date string into a moment object and format it
  return moment(dateString).format('DD.MM.YYYY - HH:mm');
}
</script>
