<template>
    <Layout>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="border rounded shadow-lg ">
            <div class="p-4">
                <p class="mt-2"><strong>{{ user.name }}</strong></p>
                <p>Off / Def / Overall: {{ user.offense_rating }} / {{ user.defense_rating }} / {{ user.overall_rating }}</p>
                <p>Wins: {{ user.wins }}</p>
                <p>Own Goals: {{ user.own_goals }}</p>

                <Link :href="'/users/edit/' + user.id">Edit User</Link>

                <form v-if="false" @submit.prevent="submit">
                    <button type="submit" :disabled="form.processing">Delete User</button>
                </form>
            </div>
        </div>
    </div>
</Layout>
</template>

<script setup>

import Layout from "../Shared/Layout.vue";
import { useForm } from "@inertiajs/vue3";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
    user: Object,
});



let form = useForm({
})

let submit = () => {
  form.delete('/users/' + props.user.id);
}
</script>

