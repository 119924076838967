import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob("./**/*.vue", { eager: true });
        return pages[`./${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        // Sentry.init({
        //     app,
        //     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        //     environment: import.meta.env.APP_ENV,
        // });
        app.use(plugin).mount(el);

        return app;
    },
});
