<template>
    <Layout>
        <section class="grid grid-cols-1 lg:grid-cols-3 gap-4">
<div class="col-span-2 pb-6">
            

    <div class="border-b border-gray-200 pb-3">
                <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                    {{ statusComuted()}}
                </span>
            </div>

            <GameResult :game="game" layout="stats"></GameResult>

            <br><br>

            <div v-if="game.status == 'started'">
                TEAM ONE: <br>
                <button @click.prevent="createEvent(1,'goal')" class="button">
                    GOOOOAAAL
                </button>
                <button @click.prevent="createEvent(1,'goalkeeper_goal')" class="button">
                    Tormann-Tor
                </button>
                
                
                <button @click.prevent="createEvent(1,'own_goal',getPlayerByTeamAndPosition(1,'defense').id)" class="button">
                    {{ getPlayerByTeamAndPosition(1,'defense').name }} Eigentor
                </button>
                <button @click.prevent="createEvent(1,'own_goal',getPlayerByTeamAndPosition(1,'offense').id)" class="button">
                    {{ getPlayerByTeamAndPosition(1,'offense').name }} Eigentor
                </button>
                
                <button @click.prevent="createEvent(1,'middle_goal',getPlayerByTeamAndPosition(1,'offense').id)" class="button">
                    Mitteltor
                </button>
                
                <br><br>
                TEAM TWO: <br>
                <button @click.prevent="createEvent(2,'goal')" class="button">
                    GOOOOAAAL
                </button>
                <button @click.prevent="createEvent(2,'goalkeeper_goal')" class="button">
                    Tormann-Tor
                </button>
                
                <button @click.prevent="createEvent(2,'own_goal',getPlayerByTeamAndPosition(2,'defense').id)" class="button">
                    {{ getPlayerByTeamAndPosition(2,'defense').name }} Eigentor
                </button>
                <button @click.prevent="createEvent(2,'own_goal',getPlayerByTeamAndPosition(2,'offense').id)" class="button">
                    {{ getPlayerByTeamAndPosition(2,'offense').name }} Eigentor
                </button>

                <button @click.prevent="createEvent(2,'middle_goal',getPlayerByTeamAndPosition(2,'offense').id)" class="button">
                    Mitteltor
                </button>
                <br><br>
                <button @click.prevent="createEvent(0,'delighted')" class="button">
                    Freuen!
                </button>
                <button @click.prevent="createEvent(0,'alert')" class="button">
                    Alarm!
                </button>
                <button @click.prevent="createEvent(0,'reset')" class="button" :disabled="!resetPossible()">
                    Reset {{ resetPossible() }}
                </button>
                <button @click.prevent="createEvent(0,'endgame')" class="button" :disabled="!endgamePossible()">
                    Endgame {{ endgamePossible() }}
                </button>
            </div>
            <div v-if="game.status == 'pending'">
                
                <button @click.prevent="createEvent(0,'startgame')" class="button">
                    Startgame
                </button>
                
            </div>

            <div v-if="false" class="text-white pt-10">
                <p>Status: {{ statusComuted() }}</p>
                <p>Alarm: {{ alarmComuted() }}</p>
                <p>Cheering: {{ game.cheering }}</p>
                
                <form v-if="false" @submit.prevent="submit">
                    <button type="submit" :disabled="form.processing">Delete Game</button>
                </form>

            </div>
        </div>
        <div class="pb-6">
            <div class="border-b border-gray-200 pb-3">
                <span class="text-lg uppercase border-l-4 border-l-emerald-500 leading-tight pl-3">
                    Highlights
                </span>
            </div>
            
            <div class="border border-gray-200 mt-6 px-4  bg-white ">
                
                <ul class="flex flex-col gap-0 p-4" >
                    <li v-for="(event, index) in game.events" class="flex">
                        <div class="flex py-4 w-full before:block before:absolute before:bg-gray-200 relative before:w-[1px] before:h-full before:ml-3.5 before:z-0" :class="{ 'pt-4' : index == 0, 'before:hidden' : index == game.events.length - 1}">
                            <span class="game-highlights-minute text-xs pl-0.5 bg-white z-10">{{ formatDateMinutes(event.created_at) }}'</span>
                            <p class="pl-4 pt-1 text-sm font-semibold">
                                <FontAwesomeIcon v-if="event.type == 'goal'" :icon="faFutbol" /> 
                                {{ getEventText(event) }}<button v-if="game.status == 'started'" class="absolute right-0 text-gray-200 hover:text-red-500" @click.prevent="deleteEvent(event.id)">X</button>
                                <span class="block text-xs place-content-center font-semibold py-2 uppercase text-gray-500" v-if="event.type == 'own_goal' && checkPreviousEvents('own_goal',event.user_id, index) % 3 === 0">Danke {{ getPlayerById(event.user_id).name }}</span>
                                <span class="block text-xs place-content-center font-semibold py-2 uppercase text-gray-500" v-if="event.type == 'middle_goal' && checkPreviousEvents('middle_goal', event.user_id, index) % 6 === 0">Danke Mitteltore {{ getPlayerById(event.user_id).name }}</span>
                            </p> 
                        </div>
                        
                    </li>

                    
                    
                </ul>
            </div>
        </div>
        </section>
        
    </Layout>
</template>

<script setup>
import Layout from "../Shared/Layout.vue";
import GameTable from "../Shared/GameTable.vue";
import GameResult from "../Shared/GameResult.vue";
import { useForm, router } from "@inertiajs/vue3";
import axios from "axios";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFutbol, faArrowsToCircle } from '@fortawesome/free-solid-svg-icons'



const props = defineProps({
    game: Object,
});



function statusComuted() {
    switch (props.game.status) {
        case "pending":
            return "Game is in creation";
        case "started":
            return "Game is in progress";
        case "ended":
            return "Game has ended";
        default:
            return props.game.status;
    }
}

function alarmComuted() {
    switch (props.game.alarm) {
        case 0:
            return "Team One triggered alarm!";
        case 1:
            return "Team Two triggered alarm!";
        default:
            return "No one";
    }
}

function checkPreviousEvents(type, userId, index) {
    const previousEvents = props.game.events.slice(index);
    return previousEvents.filter(event => event.user_id === userId && event.type === type).length;
}

function getPlayerByTeamAndPosition(team, position) {
    let player = props.game.users.filter((user) => user.pivot.team == team && user.pivot.position == position);
    return player[0];
}

function getPlayerById(id) {
    let player = props.game.users.filter((user) => user.id == id );
    return player[0];
}

function resetPossible() {
    
    if(props.game.score_team_one == 1 && props.game.score_team_two == 1) {
        return true;
    }
    if(props.game.score_team_one == 9 && props.game.score_team_two == 9) {
        return true;
    }
    return false;

}
function endgamePossible() {
    if(props.game.score_team_one >= 10 || props.game.score_team_two >= 10) {
        return true;
    }
    return false;
}

let form = useForm({
})

let submit = () => {
  form.delete('/games/' + props.game.id);
}
let formatDate = (dateString) => {
      return moment(dateString).format('HH:mm:ss');
}
let formatDateMinutes = (dateString) => {
    var gameStart = moment(props.game.events[props.game.events.length - 1].created_at);
    var eventDate = moment(dateString); // another date
    var duration = moment.duration(eventDate.diff(gameStart));
    var minutes = duration.asMinutes();
    // return Math.ceil(minutes) or 1 if 0
    if (Math.ceil(minutes) == 0) {
        return 1;
    }
    return Math.ceil(minutes);
    //   return moment(dateString).format('mm');
}

function getEventText(event) {

    switch (event.type) {
        case "goal":
            return "Team " + event.team + " scored a goal";
        case "goalkeeper_goal":
            return "Team " + event.team + " scored a goalkeeper goal";
        case "own_goal":
            return getPlayerById(event.user_id).name + " scored an own goal, bad luck!";
        case "middle_goal":
            return getPlayerById(event.user_id).name + " scored a middle goal";
        case "delighted":
            return "Cheering!";
        case "alert":
            return "Alarm!";
        case "reset":
            return "Game has been reset";
        case "endgame":
            return "Game has ended!";
        case "startgame":
            return "Game has started!";
        default:
            return event.type;
    }
}

let createEvent = (team, type, user_id = null, message_type = null) => {
    console.log(props.game.events);
    console.log(team, type, props.game.id);
    let multiplier = 1;
    if(type == 'middle_goal') {
        console.log('middle_goal', user_id);
        multiplier = Math.ceil((props.game.events.filter(event => event.user_id === user_id && event.type === type).length + 1)  / 6);
    }
    console.log(multiplier);
    let data = {
        team: team,
        type: type,
        game_id: props.game.id,
        multiplier: multiplier
    };
    if (user_id) {
        data.user_id = user_id;
    }
    if (message_type) {
        data.message_type = message_type;
    }
    
    router.post('/event', data, { preserveScroll: true })
}

let deleteEvent = (id) => {
    router.delete('/event/' + id, { preserveScroll: true })
}
</script>
