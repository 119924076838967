<template>
    <Layout>
        <h1 class="text-4xl uppercase font-bold">
        {{ headline }}
      </h1>
      <p class="mt-2">
        {{ bodytext }}
      </p>
    </Layout>
</template>

<script>

import Layout from "../Shared/Layout.vue";

export default {
    props: {
        headline: String,
        bodytext: String,
    },
    layout: Layout,
};

</script>