<template>
    
    <form @submit.prevent="submit()">
      <div class="relative shadow-lg shadow-slate-900 rounded-lg overflow-hidden">
        
        <div class="absolute w-full h-full bg-black/20 text-8xl uppercase text-white font-bold font-mono z-10 grid grid-cols-7 justify-center items-center">          
          <div class="col-span-3 justify-end relative">
            <input @input="submit()" class="w-auto max-w-xs bg-transparent text-right outline-none" :disabled="status != 'started'" type="text" v-model="form.score_team_one">
          </div>
          <div class="text-center">:</div>
          <div  class="col-span-3">
            <input @input="submit()" class="w-auto max-w-xs bg-transparent outline-none" :disabled="status != 'started'" type="text" v-model="form.score_team_two">
          </div>
          
        </div>
        <div class="absolute w-full text-xl uppercase text-white justify-center pt-10 flex z-10">
          <p class="text-center">
            {{ statusComuted() }}<br>
            DateTime: {{ game.created_at }}
          </p>
        </div>
        <div class="absolute w-full bottom-0 text-base leading-none uppercase text-white bg-black/50 z-10 grid grid-cols-7 justify-center items-end p-8">
          <div class="col-span-3">
            <template v-for="user in game.users"><div v-if="user.pivot.team == 'team_one'">{{ user.name }}</div></template>
          </div>
          <div class="text-4xl uppercase text-white text-center">
            VS
          </div>
          <div class="col-span-3 text-right">
            <template v-for="user in game.users"><div v-if="user.pivot.team == 'team_two'">{{ user.name }}</div></template>
          </div>
        </div>
        <img class="" src="/storage/Vector_20180620_039.jpg">
      </div>
    </form>
    
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  game: Object,
});

let form = useForm({
  score_team_one: props.game.score_team_one,
  score_team_two: props.game.score_team_two,
})

let submit = () => {
  console.log(form.score_team_one)
  console.log(Number.isInteger(parseInt(form.score_team_one)));
  if(Number.isInteger(parseInt(form.score_team_one)) && Number.isInteger(parseInt(form.score_team_two))) {
    form.put('/games/' + props.game.id);
  }
}

function statusComuted() {
    switch(props.game.status) {
        case 'pending':
            return 'Game is in creation'
        case 'started':
            return 'Game is in progress'
        case 'ended':
            return 'Game has ended'
        default: 
            return props.game.status;
    }
}
</script>