<template>
    <Layout>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div class="border rounded shadow-lg">
                <div class="p-4">
                    

                    <form @submit.prevent="submit">

                        <label for="name" class="block">Name</label>
                        <input id="name" type="text" name="name" v-model="form.name" class="w-full border p-2 rounded mb-4" />

                        <label for="email" class="block">Email</label>
                        <input id="email" type="email" name="email" v-model="form.email" class="w-full border p-2 rounded mb-4" />

                        <button class="uppercase text-white bg-slate-600 p-4 rounded hover:bg-slate-400 hover:text-black transition-colors" type="submit" :disabled="form.processing">
                            Create Player
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script setup>
import Layout from "../Shared/Layout.vue";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
    user: Object,
});

let form = useForm({
    name: "",
    email: "",
});

let submit = () => {
    form.post("/users/");
};
</script>
